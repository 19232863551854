<template>
  <div class="100vh mt-15">
    <v-container bg fill-height grid-list-md text-xs-center>
      <v-layout row wrap align-center>
        <v-flex>
          <v-img
            class="mb-16 mx-auto"
            width="185px"
            height="60px"
            src="@/assets/logo/facenet.png"
          ></v-img>
          <v-card rounded="md" flat class="wd-486 ma-auto pa-4">
            <v-card-title class="font-24 fw-600 greyColor--text mb-3"
              >Recover your password</v-card-title
            >
            <v-card-subtitle class="font-14 fw-400 secondary--text mb-4"
              >Enter an email to get a recovery link to reset your
              password.</v-card-subtitle
            >
            <v-form ref="forgotPassword" @submit.prevent="forgotPassword">
              <v-card-text>
                <label class="font-13 greyColor--text fw-500">Email</label>
                <v-text-field
                  v-model="formData.email"
                  class="my-3 login-page font-13 fw-500"
                  placeholder="Enter email address"
                  dense
                  height="48px"
                  outlined
                  hide-details="auto"
                  :rules="emailRules"
                 
                  required
                ></v-text-field>
              </v-card-text>
              <v-card-actions class="pa-3 mt-3">
                <v-btn
                  color="primary"
                  height="50px"
                  block
                  depressed
                  type="submit"
                  :loading="isLoading"
                  class="textColor--text ml-1 text-transform-none"
                  >Send</v-btn
                >
              </v-card-actions>
            </v-form>
            <back-to-login />
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import ApiService from "@/services/Api";
import BackToLogin from "@/components/common/buttons/BackToLogin.vue";
import { showErrorMessage, showSimpleErrorMessage } from "@/utils/showError";
export default {
  components: { BackToLogin },
  data() {
    return {
      isLoading: false,
      formData: {
        email: "",
      },
      emailRules: [
        (v) => !!v || "Email is required",
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(v) ||
          "E-mail must be valid",
      ],
    };
  },
  methods: {
    /**
     * Method to send email to the register email, in case user forgets his/her password
     */
    forgotPassword() {
      if (!this.$refs.forgotPassword.validate()) {
        return showSimpleErrorMessage("Please fill required fields correctly!");
      }
      this.isLoading = true;
      ApiService.PostRequest("/merchant/forget-password", this.formData)
        .then(() => {
          this.isLoading = false;
          localStorage.setItem("forgot-email", this.formData.email);
          this.$router.push({ path: "/email-verify" });
          this.formData = {};
        })
        .catch((error) => {
          this.isLoading = false;
          showErrorMessage(error);
        });
    },
  },
};
</script>
